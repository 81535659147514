<template>
  <div class="m-3 wrapper  wrapper--default">
    <h2 class="text-center">Stadagen - Gesorteerd</h2>
    <Loading v-if="loading" />
    <div v-if="!loading" class="w-100 p-2">
      <button class="m2 employee_download" @click="createExcel">Totaallijst voertuigen</button>
      <StockStageDaysTable :table_data="purchase_duo_stock_data" :table_style="200" />
    </div>
  </div>
</template>

<script>
import StockStageDaysTable from "@/components/StockStageDaysTable.vue";
import Loading from "@/components/Loading.vue"
import request from "@/functions/request.js";
import * as XLSX from "xlsx";

export default {
  props: ["bu"],
  components: { StockStageDaysTable, Loading },
  data: () => ({
    purchase_duo_stock_data: null,
    table_bu: null,
    loading: null,
    excel_export_data: null,
  }),
  created() {
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      request(`purchaseduo/stadagen-per-bob`, "GET").then(
        ({ purchase_duo_stock_data, excel_export_data }) => {
          this.purchase_duo_stock_data = purchase_duo_stock_data
          this.excel_export_data = excel_export_data;
          this.loading = false;
        }
      );
    },

    createExcel() {
      this.vehicles_stadays_bu = [];

      this.excel_export_data.forEach((i) => {
        this.vehicles_stadays_bu.push({
          "Nummer": i.nummer,
          "Merknaam": i.merknaam,
          "Typenaam": i.typenaam,
          "Regjaar": Number(i.regjaar),
          "Inkoopbedrag": Number(i.inkoopbedrag.replace(/,/g, '')),
          "Kostenbedrag": Number(i.kostenbedrag.replace(/,/g, '')),
          "Vraagprijs": Number(i.Vraagprijs.replace(/,/g, '')),
          "Stadagen": i.stadagen,
          "Verwachte marge": i.expectedmargin,
          "Afschrijving": i.afschrijving,
        });
      });

      var telWS = XLSX.utils.json_to_sheet(this.vehicles_stadays_bu);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        wb,
        telWS,
        `stadagen_${this.bu}_${this.moment().format("DD-MM-YYYY")}`
      );
      XLSX.writeFile(
        wb,
        `stadagen_${this.bu}_${this.moment().format("DD-MM-YYYY")}.xlsx`
      );
    }
  },
};
</script>

<style></style>