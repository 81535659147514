<template>
    <table :class="`w-100 table--perweek table__border--${table_style}`">
        <thead :class="`table__head--${table_style}`">
            <tr>
                <th class="table__head--text">Itemnummer</th>
                <th class="table__head--text">Merk</th>
                <th class="table__head--text">type</th>
                <th class="table__head--text">Reg. jaar</th>
                <th class="table__head--text">Inkoopbedrag</th>
                <th class="table__head--text">Kostenbedrag</th>
                <th class="table__head--text">Vraagprijs</th>
                <th class="table__head--text">Stadagen</th>
                <th class="table__head--text">Verwachte marge</th>
                <th class="table__head--text">Afschrijving</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(item, key) in table_data" :key="key" :class="`table__row--color`">
                <td class="table__cell--default text-center"><ImageHover :itemnummer="item.nummer" :bu="item.bu" /></td>
                <td class="table__cell--default">{{ item.merknaam }}</td>
                <td class="table__cell--default">{{ item.typenaam }}</td>
                <td class="table__cell--default">{{ item.regjaar }}</td>
                <td class="table__cell--default table__data--right">€ {{ item.inkoopbedrag }}</td>
                <td class="table__cell--default table__data--right">€ {{ item.kostenbedrag }}</td>
                <td class="table__cell--default table__data--right">€ {{ item.Vraagprijs }}</td>
                <td class="table__cell--default text-center">{{ item.stadagen }}</td>
                <td class="table__cell--default table__data--right">€ {{ item.expectedmargin }}</td>
                <td class="table__cell--default table__data--right">€ {{ item.afschrijving }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import ImageHover from "@/components/ImageHover.vue";

export default {
    components: { ImageHover },
    props: {
        table_data: [Array, Object],
        table_style: String,
    }

}
</script>
